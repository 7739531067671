export default {
    "home.user.paper.finalVersion.ENFileFinalversion":"英文檔案",
    "home.user.paper.finalVersion.CNFileFinalversion":"中文檔案",
    "home.user.paper.finalVersion.CopyrightConfirmation":"著作權確認",
    "home.user.paper.finalVersion.PrivacyConfirmation":"隱私權確認",
    "home.user.paper.button.UploadPaper":"上傳稿件",
    "home.user.paper.attr.no":"稿件編號",
    "home.user.paper.column.chinese":"中文",
    "home.user.paper.column.english":"英文",
    "home.user.paper.row.button.view":"查看",
    "home.user.paper.row.button.UploadFinalVersionPaper":"上傳定稿",
    "home.user.paper.row.button.SubmitPaper":"提交審核",
    "home.user.paper.row.button.Edit":"編輯",
    "home.user.paper.action.check.delete":"確認要刪除",
    "home.user.paper.action.cancel.submit":"確認要撤回",
    "home.user.paper.row.button.Back":"退回",
    "home.user.paper.action.check.submit":"確認要提交",
    "home.user.paper.frame.title.new":"新增",
    "home.user.paper.frame.title.UploadFinalVersionPaper":"上傳定稿",
    "system.updateSuccess":"更新成功",
    "system.createSuccess":"建立成功",
    "system.sendNewPasswordSuccess":"已發送新密碼到指定的信箱",
    "system.notFoundAnnouncement":"無此資料",
    "system.registrationSuccess":"註冊成功",
    "system.signupSuccess":"註冊成功, 已發送密碼到您的信箱",
    "error.40100":"請先登入",
    "error.40101":"登入已過期",
    "error.50101":"尚未開放註冊",
    "error.50102":"此帳號未註冊",
    "error.50103":"帳號或密碼錯誤",
    "error.50104":"舊密碼錯誤",
    "error.50105":"信箱不可空白",
    "error.50106":"帳號已被使用",
    "error.50107":"此帳號未設定 Email 請聯繫管理員",
    "error.50108":"錯誤，資料重複: {{data}}",
    "error.50109":"錯誤，資料重複",
    "error.50110":"錯誤，資料已存在，請重新整理",
    "error.50111":"無權限",
    "error.50112":"帳號目前禁用，請聯繫管理員",
    "error.50001":"系統異常, 請聯繫管理員",
    "error.510":"系統異常, 請聯繫管理員",
    "error.500":"系統異常, 請聯繫管理員",
    "home.user.login":"登入",
    "home.user.profile":"個人資料",
    "home.user.my-papers":"我的稿件",
    "home.user.logout":"登出",
    "home.announcements":"最新公告",
    "home.acceptedPapers":"已接受之論文清單",
    "home.conferenceInfos":"研討會資訊",
    "home.conferenceInfos.introdution":"簡介",
    "home.conferenceInfos.lecturer":"大會講者",
    "home.conferenceInfos.agenda":"大會議程",
    "home.conferenceInfos.invite-info":"邀稿通知",
    "home.conferenceInfos.registration-fee":"註冊費用",
    "home.conferenceInfos.files":"檔案下載",
    "home.conferenceInfos.activities":"研討會活動報名",
    "home.conferenceInfos.pasticei":"歷屆研討會",
    "home.SubmissionDeadline":"摘要投稿截止",
    "home.FinalSubmissionDeadline":"定稿上傳截止",
    "home.RegistrationDeadline":"活動報名截止",
    "home.PublishReviewResultDeadline":"公布審稿結果",
    "home.ReviewDeadline":"審稿截止",
    "home.lang.chinese":"中文",
    "home.lang.english":"英文",
    "home.user.profile.account":"帳號",
    "home.user.profile.name":"姓名",
    "home.user.profile.nationality":"國籍",
    "home.user.profile.department":"單位",
    "home.user.profile.unit":"部門",
    "home.user.profile.telephoneNumber":"電話",
    "home.user.profile.cellphoneNumber":"行動電話",
    "home.user.profile.zipCode":"區號",
    "home.user.profile.address":"地址",
    "home.user.paper.content.attr.cnTitle":"中文標題",
    "home.user.paper.content.attr.enTitle":"英文標題",
    "home.user.paper.content.attr.cnAbstract":"中文摘要",
    "home.user.paper.content.attr.enAbstract":"英文摘要",
    "home.user.paper.content.attr.cnKeywords":"中文關鍵字",
    "home.user.paper.content.attr.cnKeywords.tip":"請用\";\"分隔多個關鍵字, 最多5個",
    "home.user.paper.content.attr.enKeywords":"英文關鍵字",
    "home.user.paper.content.attr.enKeywords.tip":"請用\";\"分隔多個關鍵字, 最多5個",
    "home.user.paper.content.attr.CallForPaperTypeGroup":"徵稿群組",
    "home.user.paper.content.attr.option.all":"全部",
    "home.user.paper.content.attr.CallForPaperType":"徵稿類別",
    "home.user.paper.content.attr.PaperType":"稿件類別",
    "home.user.paper.content.attr.Authors":"作者",
    "home.user.paper.content.attr.Authors.tip":"請用\";\"分隔多位作者",
    "home.user.paper.content.button.cancel":"取消",
    "home.user.paper.content.button.create":"建立",
    "home.user.paper.content.button.update":"更新",
    "home.user.paper.content.hint.word":"請輸入",
    "home.user.paper.content.hint.overLengthOfWords":"字數已超過標準",
    "home.user.paper.content.attr.author1":"作者1",
    "home.user.paper.content.attr.author1Unit":"作者1 所屬單位",
    "home.user.paper.content.attr.author1ContactAuthor":"預設通訊作者",
    "home.user.paper.content.attr.author1ContactWay":"作者1 Email",
    "home.user.paper.content.attr.author2":"作者2",
    "home.user.paper.content.attr.author2Unit":"作者2 所屬單位",
    "home.user.paper.content.attr.author2ContactAuthor":"預設通訊作者",
    "home.user.paper.content.attr.author2ContactWay":"作者2 Email",
    "home.user.paper.content.attr.author3":"作者3",
    "home.user.paper.content.attr.author3Unit":"作者3 所屬單位",
    "home.user.paper.content.attr.author3ContactAuthor":"預設通訊作者",
    "home.user.paper.content.attr.author3ContactWay":"作者3 Email",
    "home.user.paper.content.attr.author4":"作者4",
    "home.user.paper.content.attr.author4Unit":"作者4 所屬單位",
    "home.user.paper.content.attr.author4ContactAuthor":"預設通訊作者",
    "home.user.paper.content.attr.author4ContactWay":"作者4 Email",
    "home.user.paper.content.attr.author5":"作者5",
    "home.user.paper.content.attr.author5Unit":"作者5 所屬單位",
    "home.user.paper.content.attr.author5ContactAuthor":"預設通訊作者",
    "home.user.paper.content.attr.author5ContactWay":"作者5 Email",
    "home.user.paper.content.attr.author6":"作者6",
    "home.user.paper.content.attr.author6Unit":"作者6 所屬單位",
    "home.user.paper.content.attr.author6ContactAuthor":"預設通訊作者",
    "home.user.paper.content.attr.author6ContactWay":"作者6 Email",
    "home.user.paper.content.attr.email.format.error":"請輸入Email格式",
    "home.user.paper.content.attr.cantempty":"不可空白"
    }