import React, { useCallback, useState, useEffect } from 'react';
import { DataContext } from '../../../Context/DataContext';
import { Spin, Modal, Button, Typography, Layout, Dropdown, Avatar, Card, message, theme } from 'antd';
import { localStorageUtils } from '../../../utils/localStorageUtils'
import axios from 'axios';
import './HomePage.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import efn from './HomePageFns'
import InviteInfo from '../InviteInfo/InviteInfo';
import Introduction from '../Introduction/Introduction';
import Lecturer from '../Lecturer/Lecturer';
import RegistrationFee from '../RegistrationFee/RegistrationFee';
import Announcement from '../Announcement/Announcement';
import ApprovedPapers from '../ApprovedPapers/ApprovedPapers'
import File from '../File/File';
import Agenda from '../Agenda/Agenda';
import Sponser from './Sponser'
import Navigation from './Navigation'
import Login from './Login/Login'
import SignUp from './Login/SignUp'
import ResetPwd from './Login/ResetPwd'
import ForgotPwd from './Login/ForgotPwd'
import queryString from 'query-string';
//User
import UserSubmissionPaper from './User/UserSubmissionPaper/UserSubmissionPaper';
import Profile from './User/Profile/Profile';


function HomePage() {
  const { globalData, t } = React.useContext(DataContext)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [config, setConfig] = useState({})
  const [title, setTitle] = useState("")
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    content: ""
  })
  const setFuncMap = {
    message, setIsPageLoading, setConfig, setModalConfig, setTitle, t
  }
  const { feature } = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  let params = queryString.parse(location.search)
  useEffect(() => {
    processAction(params.action, config, setFuncMap)
    efn.getHomepage(setFuncMap)
    efn.setFeatureTitle(location, setFuncMap)
  }, [location.search, location.pathname, globalData.langChangeTime, config])
  return (
    <div className="HomePage">
      <Spin spinning={isPageLoading}>
        <div className="container">
          <div className="banner">
            {config.BannerIamge && <img src={`${window.CONFS.cloudDomain}/${config.BannerIamge}`} />}
          </div>
          <div className="content">
            {efn.generateScheduledDeadlineTime(config, t)}
            <Navigation isPublishReviewResult={config.IsPublishReviewResult} />
            <div className="ct">
              <div>{title}</div>
              {/* {`${feature}-${action}-${id}`} */}
              {feature === "invite-info" && <InviteInfo />}
              {feature === "introdution" && <Introduction />}
              {feature === "submission-paper" && <UserSubmissionPaper />}
              {feature === "lecturer" && <Lecturer />}
              {feature === "agenda" && <Agenda />}
              {feature === "registration-fee" && <RegistrationFee />}
              {feature === "profile" && <Profile />}
              {(feature === "announcements" || (!feature)) && <Announcement />}
              {feature === "files" && <File />}
              {feature === "accepted-papers" && <ApprovedPapers />}

            </div>
          </div>
          {config.links && config.links.length > 0 && <Sponser links={config.links} />}
          <div className="footer" dangerouslySetInnerHTML={{ __html: config.Footer }}></div>
        </div>
        <Modal wrapClassName="modal-theme" title={modalConfig.title} open={modalConfig.isOpen} footer={[]} destroyOnClose
          onCancel={() => {
            if (params.action == "login") {
              navigate("/", { replace: true })
            }
            else {
              navigate(location.pathname, { replace: true })
            }
          }}>
          {modalConfig.content}
        </Modal>
      </Spin>
    </div>
  );
}


const processAction = function (action, config, setFuncMap) {
  let isRegistrationICEIAvailable = config.IsRegistrationICEIAvailable
  switch (action) {
    case "login":
      setFuncMap.setModalConfig({
        isOpen: true,
        title: "",
        content: <Login setFuncMap={setFuncMap} isRegistrationICEIAvailable={isRegistrationICEIAvailable} />
      })
      break;
    case "sign-up":
      if (isRegistrationICEIAvailable == 1) {
        setFuncMap.setModalConfig({
          isOpen: true,
          title: "",
          content: <SignUp setFuncMap={setFuncMap} />
        })
      }
      break;
    case "reset-password":
      setFuncMap.setModalConfig({
        isOpen: true,
        title: "",
        content: <ResetPwd setFuncMap={setFuncMap} />
      })
      break;
    case "forgot-password":
      setFuncMap.setModalConfig({
        isOpen: true,
        title: "",
        content: <ForgotPwd setFuncMap={setFuncMap} />
      })
      break;
    default:
      setFuncMap.setModalConfig({
        isOpen: false,
        title: "",
        content: ""
      })

  }

}

export default HomePage;
